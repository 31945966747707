import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

function Datenschutz() {
  return (
    <>
      <Helmet>
        <title>Datenschutz - Electric Motion Fachhändler Dirk Mielke</title>
      </Helmet>
      <Container>
        <h1>Datenschutz</h1>
        
      </Container>
    </>
  )
}

export default Datenschutz