import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

function ModelCard(props) {
  return (
    <Card bg="light" className="border-0 mb-4 flex-fill">
      <Card.Body className="p-0">
        <Row>
          <Col>
            <Card.Img className="mb-3 shadow" src={props.picture} />
          </Col>
          <Col>
            <Card.Title className="fw-bold">{props.title}</Card.Title>
            <Card.Text>{props.description}</Card.Text>
            <Card.Link
              as={Link}
              to="#kontakt"
              href="#kontakt"
              onClick={() => window.location.replace("#kontakt")}
            >
              {props.model1} ab {props.model1price} bestellen
            </Card.Link>
            <br />
            <Card.Link
              as={Link}
              to="#kontakt"
              href="#kontakt"
              onClick={() => window.location.replace("#kontakt")}
            >
              {props.model2} ab {props.model2price} bestellen
            </Card.Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ModelCard
