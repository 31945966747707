import React, {  useState } from "react"
import { FloatingLabel, Form, Row, Col, Button, Alert } from "react-bootstrap"
import Captcha from "./Captcha"

function ContactForm() {
  const [name, setName] = useState("")
  const [isNameInvalid, setIsNameInvalid] = useState(false)
  const [email, setEmail] = useState("")
  const [isEmailInvalid, setIsEmailInvalid] = useState(false)
  const [message, setMessage] = useState("")
  const [isMessageInvalid, setIsMessageInvalid] = useState(false)
  const [solution, setSolution] = useState("")
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [deactivated, setDeactivated] = useState(false)

  const handleSubmit = async (e) => {
    setDeactivated(true)
    e.preventDefault()
    let errors = 0
    if (name.replaceAll(" ", "").length === 0) {
      setIsNameInvalid(true)
      errors++
    }
    if (email.replaceAll(" ", "").length === 0) {
      setIsEmailInvalid(true)
      errors++
    }
    if (message.replaceAll(" ", "").length === 0) {
      setIsMessageInvalid(true)
      errors++
    }

    if (errors === 0) {
      const res = await fetch("https://api.lie-bold.de/em/", {
        method: "POST",
        body: JSON.stringify({
          solution: solution,
          name: name,
          email: email,
          message: message,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      const body = await res.json()
      if (await body.success) {
        setSent(true)
      } else {
        setError(true)
        setErrorMessage(body.message)
        setDeactivated(false)
      }
    }
  }

  return (
    <>
      <Form.Floating className="mb-3">
        <Row className="justify-content-center">
          <Col className="mb-3" lg="12" xl="4">
            <h3>Kontakt-Informationen</h3>
            <p>
              E-Mail: emkfz@gmx.de
              <br />
              Telefon: 05208 390
            </p>
            {/* <hr /> */}
            <h3>Adresse</h3>
            <p>
              Schötmarsche Str. 21a
              <br />
              33818 Leopoldshöhe
            </p>
            {/* <hr /> */}
            <h3>Öffnungszeiten</h3>
            <p>
              Mo. - Do.: 8 - 17 Uhr
              <br />
              Fr.: 8 - 16 Uhr
            </p>
          </Col>
          <Col lg="12" xl="8" className="order-first order-xl-last mb-5">
            <h2 className="">
              Bei Interesse an einem Electric Motion Bike oder weiteren Fragen,
              kontaktieren Sie mich gerne. 👋
            </h2>
            {error && (
              <Alert variant="danger" className="mt-3">
                Es gab ein Problem beim Senden der Nachricht. Bitte versuchen
                Sie es erneut. ({errorMessage})
              </Alert>
            )}
            {!sent && (
              <Form
                onSubmit={(e) => {
                  handleSubmit(e)
                }}
              >
                <Row xs={1} md="2">
                  <Col>
                    <FloatingLabel
                      controlId="name"
                      label="Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Max Mustermann"
                        onChange={(e) => setName(e.target.value)}
                        isInvalid={isNameInvalid}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel
                      controlId="email"
                      label="E-Mail-Adresse"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                        onChange={(e) => setEmail(e.target.value)}
                        isInvalid={isEmailInvalid}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <FloatingLabel
                  controlId="nachricht"
                  label="Nachricht"
                  onChange={(e) => setMessage(e.target.value)}
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Was möchten Sie mir mitteilen?"
                    style={{ height: "100px" }}
                    isInvalid={isMessageInvalid}
                  />
                </FloatingLabel>
                <Captcha setSolution={setSolution} />
                <Button className="w-100 mt-3" type="submit" disabled={deactivated}>
                  Nachricht senden
                </Button>
              </Form>
            )}

            {sent && (
              <Alert variant="success" className="mt-3">
                Nachricht erfolgreich gesendet.
              </Alert>
            )}
          </Col>
        </Row>
      </Form.Floating>
      <Row></Row>
    </>
  )
}

export default ContactForm
