import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Logo from './../../images/electric-motion-logo.svg'

import ExtLink from './../../components/ExtLink'

function Reseller(props) {
    return (
        <>
            <Row className="row-cols-1 row-cols-lg-2 align-items-center mb-5">

                {props.image &&
                    <Col className={(props.align === "end" ? "order-lg-2" : " ") + " mb-4"}>
                        <img style={{ aspectRatio: "16/9", objectFit: "cover", objectPosition: "center" }} src={props.image} width="100%" className="rounded shadow" />
                    </Col>
                }

                <Col>

                    {props.logo &&
                        <img className="mb-4" width="150px" src={Logo} />
                    }

                    {props.title &&
                        <h2 className="mb-4">{props.title}</h2>
                    }

                    {props.cta &&
                        <ExtLink title={props.cta.title} url={props.cta.url} icon={props.cta.icon} />
                    }

                </Col>
            </Row>
        </>
    )
}

export default Reseller