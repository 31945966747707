import React from 'react'
import { Link } from 'react-router-dom'
import Artikel from '../templates/Artikel'

import ElektroTrialImage from './../images/elektro-trial_electric-motion.webp'

function ElektroTrial() {
  return (
    <Artikel title="Elektro Trial" image={ElektroTrialImage} content={
      <>
        <h2 id="Das ist anders bei Elektro Trial Bikes">Das ist anders bei Elektro Trial Motorrädern</h2>
        <p>
          Elektro-Motorräder haben sich in den letzten Jahren sehr verbessert. Sind sie eine Überlegung wert oder liegt der alltagstaugliche Elektromotor noch weit entfernt?
        </p>
        <h3 id="kupplung">Keine Kupplung?</h3>
        <p>
          Bikes mit Elektroantrieb kommen <b>aufgrund ihrer Bauart grundsätzlich ohne Kupplung</b> aus.
          Das erleichtert einigen Fahranfängern den Einstieg. Vielen erfahrenen Elektro Trial Fahrern ist die Kupplung aber sehr wichtig,
          denn sie brauchen optimale Kontrolle über das Bike, oder sind diese von ihrer Verbrenner-Maschine gewohnt.
        </p>
        <p>
          Viele Trial Profis setzen deshalb auf <Link to="/2023">Electric Motion</Link>. Dieser Hersteller verkauft <b>Elektro Trial Motorräder mit optionaler Kupplung</b>.
          Die Kupplung kann bei den ersten Übungsversuchen als Anfänger deaktiviert werden, um sich auf die Basics besser konzentrieren zu können.
          Als Profi kann man die Kupplung auch aktivieren, um das Fahrverhalten noch besser zu kontrollieren.
        </p>
        <h3 id="emissionen-motorsound">Emissionen und Motor-Sound</h3>
        <p>
          Elektro Fahrzeuge ernähren sich ausschließlich von Strom und kommen gänzlich ohne Benzin aus.
          Die verringerten Emissionen sind ein klares Argument für die Wahl eines Elektro-Motorrads.
        </p>
        <p>
          Elektro-Motoren machen auch keinen lauten Sound, so wie ein klassischer Benzinmotor.
          Einige werden den fehlenden Krach wohl vermissen, Elektro aber steht nicht für polarisieren, sondern für Präzision und Zuverlässigkeit.
          Viele Maschinen geben aber trotzdem ein Science-Fiction-mäßiges Surren von sich.
          Das ist nicht Jedermanns Sache, aber trotzdem deutlich angenehmer für die Nachbarn, als der Sound von herkömmlichen Maschinen.
        </p>
        <h2 id="akku">Alles rund um den Akku</h2>
        <p>
          So penibel wie vor zehn Jahren muss keiner mehr auf seinen Akkustand achten, denn in den letzten Jahren hat es einige wichtige Verbesserungen im Bereich der Reichweite gegeben.
        </p>
        <p>
          Ich erinnere mich noch gut, als das Motorrad für 20 Minuten Trial Spaß drei Stunden an der Steckdose hing. Zum Glück ist das heute nicht mehr so. Aber wie lange denn wirklich?
        </p>
        <h3 id="reichweite-ladezeit">Reichweite und Ladezeit</h3>
        <p>
          Die meisten Hersteller geben eine Reichweite von ca. 60 Kilometer an.
          Bekannterweise fährt man beim Trial nicht so energiesparend,
          die Reichweiten-Angabe ist daher mit Vorbehalt zu sehen und eher interessant für Marathon-Events oder den Gebrauch auf der Straße.
          Trotzdem reicht ein Ladevorgang für die meisten Fahrer völlig aus, wenn nicht bieten die meisten Hersteller auch Ersatz-Akkus an, die einfach mit den Leeren getauscht werden.
        </p>
        <p>
          Electric Motion verbaut in ihren Motorrädern zusätzlich eine elektrische Motorbremse, die Energie zurückgewinnen kann.
          Das ist hilfreich, wenn man einen Berg herunterfährt oder kurz ausrollen kann. Solche Technologien sind zwar nicht weltverändernd, die extra 2% schenkt man seinem Akku aber gerne.
        </p>
        <h3 id="ladevorgang-ablauf">Ablauf eines Ladevorgangs</h3>
        <p>
          Genauso wie die herkömmlichen Trial Motorräder wird auch dein elektrisches Pendant für den Privatgebrauch gebaut.
          Somit ist das Laden einfach per Netzteil über die heimische Schuko-Steckdose möglich.
        </p>
        <p>
          Das Netzteil wird einfach in die Steckdose gesteckt und dann an die Maschine angeschlossen, der Ladevorgang beginnt automatisch und endet auch automatisch, wenn der Akku voll ist.
        </p>
        <h3 id="ladevorgang-dauer">Dauer eines Ladevorgangs</h3>
        <p>
          Die Ladezeit hängt immer von der Leistung des Netzteiles ab.
          Viele Hersteller bieten Normale- und Schnelladenetzteile für die ganz eiligen an. Auch von Hersteller zu Hersteller variieren die Ladezeiten.
        </p>
        <p>
          Generell kann man aber sagen, dass man beim Schnelladen ca. 2 Stunden braucht und beim normalen Laden ca. 3,5 Std.
        </p>
        <p>
          Gesagt sei jedoch, dass die Akkus bei dauerhaftem Schnelladen deutlich schneller verschleißen als beim normalen Ladevorgang.
          Das Schnelladen sollte also eher auf Momente beschränkt werden, in denen das Motorrad wirklich schnell wieder einsatzfähig sein soll, zum Beispiel an der Rennpiste.
        </p>
        <h2 id="sind-elektro-trial-bikes-schwächer">Sind Elektro Trial Bikes schwächer?</h2>
        <h3 id="gewicht">Gewicht</h3>
        <p>
          Herkömmliche Trial Motorräder wiegen rund 70 kg. Elektrische Maschinen bewegen sich in genau derselben Gewichtsklasse und behindern sich wegen ihres Gewichts auf keinen Fall selbst.
        </p>
        <p>
          Wie bei einem BMX-Fahrrad liegt der Schwerpunkt hier sehr tief.
          Deshalb werden Elektro Trial Motorräder häufig im Stehen gefahren.
          Es gibt aber auch einige Motorräder mit Sitzfläche, sodass auch ein normales Fahren möglich ist.
        </p>
        <h3 id="geschwindigkeit">Geschwindigkeit</h3>
        <p>
          Versucht man gerade einen Berg mit seinem Motorrad zu erklimmen, ist die Höchstgeschwindigkeit im ersten Moment eher uninteressant.
          Auch bei den offiziellen Herstellerseiten werden Sie kaum km/h Angaben finden. Der Topspeed der Maschine hängt auch stark von der Fahrergröße, dem Fahrwerk und dem Gewicht ab.
        </p>
        <p>
          Sicher ist aber, dass das E-Trial Motorrad keinen bauartbedingten Nachteil gegenüber dem herkömmlichen Verbrenner hat.
        </p>
        <h2 id="wie-teuer-sind-e-trial-bikes">Wie teuer sind E-Trial Bikes?</h2>
        <h3 id="anschaffungspreis">Anschaffungspreis</h3>
        <p>
          Die günstigsten Elektro Trial Motorräder für Erwachsene kosten um die 8.000 €. Für ein noch besser ausgestattetes Motorrad können die Preise im fünfstelligen Bereich liegen.
        </p>
        <p>
          Maschinen für Kinder sind dementsprechend günstiger, man sollte aber mindestens 2.000 € auf den Tisch legen. Gute Elektro Trial Motorräder für Kinder bieten die Hersteller Torrot oder Oset.
        </p>
        <h3 id="wartung">Wartung</h3>
        <p>
          Da ein elektrischer Motor selbst kaum wartungsbedürftig ist, ist auch das E-Trial Motorrad wartungsarm.
        </p>
        <p>
          Nur der Akku muss geladen und die Kette geölt werden. Das ist im Vergleich zum Verbrenner ein deutlicher Vorteil, der auch das Motorrad auf der Piste deutlich zuverlässiger macht.
        </p>
        <p>
          Das heißt nicht, dass eine Elektro-Maschine wartungsfrei ist. Auch beim E-Motorrad kann ein Lager ausschlagen oder eine Dichtung undicht werden.
        </p>
        {/* <img className="mh-50 rounded mw-100 float-md-end mb-3 mx-auto ms-md-3 border border-secondary" src="{Reiger}" /> */}
      </>

    } />
  )
}

export default ElektroTrial