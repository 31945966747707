import React from 'react'
import { Container, Row } from 'react-bootstrap'
import ModelCard from './Modelle/ModelCard'

import Epure from './../images/epure-electric-motion_card-picture.webp'
import PostPlaceholder from './../images/post-placeholder.svg'
import { Helmet } from 'react-helmet'

function Modelle() {
  return (
    <>
      <Helmet>
        <title>Modelle - Electric Motion Fachhändler Dirk Mielke</title>
      </Helmet>
      <Container className="mt-3">
        <img className="hero rounded shadow my-3 w-100" src={PostPlaceholder} />
        <h1 className="ausprobe text-center display-5">Modelle</h1>
        <hr />
        <Row xs={1} md={2} lg={4}>
          <ModelCard title="Epure 2023" picture={Epure} description='Das "Wettkampf"-Sport-Modell mit Straßenzulassung und Kupplung.' price="9.698€" />
          <ModelCard title="Epure 2023" picture={Epure} description='Das "Wettkampf"-Sport-Modell mit Straßenzulassung und Kupplung.' price="9.698€" />
          <ModelCard title="Epure 2023" picture={Epure} description='Das "Wettkampf"-Sport-Modell mit Straßenzulassung und Kupplung.' price="9.698€" />
          <ModelCard title="Epure 2023" picture={Epure} description='Das "Wettkampf"-Sport-Modell mit Straßenzulassung und Kupplung.' price="9.698€" />
        </Row>
      </Container>
    </>
  )
}

export default Modelle