import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ExtLink from '../../components/ExtLink'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import HeroImage from './../../images/epure-2023-electric-motion-hero.webp'

function Hero() {
    return (
        <Row className="mb-5 my-3 align-items-center row-cols-1 row-cols-lg-2">
            <Col className="">
                <img width="100%" src={HeroImage}></img>
            </Col>
            <Col>
                <div className=''>
                    <h1 className="display-1">Leise.<br />Kraftvoll.<br /><span className="fw-bold">Elektrisch.</span></h1>
                    <ExtLink title="Verfügbare Modelle ansehen" url="#modelle" icon={faArrowRight} />
                </div>

            </Col>

        </Row>
    )
}

export default Hero