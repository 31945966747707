import React, { useState, useEffect } from 'react'

import { faList, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import CollapseTOC from './CollapseTOC'
import { useHeadsObserver } from './Observer'
import { Nav } from 'react-bootstrap'

function TOC(props) {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (window.innerWidth >= 992) {
            setOpen(true);
        } else {
            setOpen(false)
        }
    }, [])

    const [icon, setIcon] = useState(faList);

    useEffect(() => {
        if (open) {
            setIcon(faCaretDown);
        } else {
            setIcon(faList);
        }
    }, [open, props.opened, props.closed])

    const toggleOpen = () => {
        if (window.innerWidth < 992) {
            setOpen(!open);
        }
    }

    const { activeId } = useHeadsObserver()
    const [headings, setHeadings] = useState([])
    useEffect(() => {
        const elements = Array.from(document.querySelectorAll("h2, h3, h4"))
            .filter(elem => elem.id.length > 0)
            .map((elem) => ({
                id: elem.id,
                text: elem.innerText,
                level: Number(elem.nodeName.charAt(1))
            }))
        setHeadings(elements)
    }, [])

    return (
        <CollapseTOC
            className="sticky-top sticky-lg-bottom bg-light shadow"
            title={"Inhalt: " + props.title}
            open={open}
            setOpen={setOpen}
            icon={icon}
            opened={faCaretDown}
            closed={faList}
            content={
                <>
                    <Nav onSelect={() => { toggleOpen() }} as="nav">
                        {headings.map((heading) => (
                            <Nav.Item className={"w-100 " + (heading.level > 2 ? "ms-3" : "")} key={heading.id} >
                                <Nav.Link className={"py-1 text-decoration-none " + (activeId === heading.id ? "fw-bold" : "opacity-75")} href={`#${heading.id}`} eventKey={heading.id}>{heading.text}</Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </>} />
    )
}

export default TOC