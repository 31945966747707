import React from 'react'

import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function CollapseTOC(props) {
    return (
        <div className={"border rounded border-secondary px-4 py-3 my-2 " + props.className}>
            <Button
                variant="link"
                onClick={() => props.setOpen(!props.open)}
                aria-controls="collapsable-text"
                aria-expanded={props.open}
                className="mb-0 p-0 text-decoration-none d-flex align-items-center w-100"
            >
                <FontAwesomeIcon className="me-2" icon={props.icon} size="sm" />
                <h2 className="fs-3 mb-0 d-inline text-start">{props.title}</h2>
            </Button>

            <Collapse in={props.open}>
                <div id="collapsable-text">
                    <hr></hr>
                    {props.content}
                </div>
            </Collapse>
        </div>
    )
}

export default CollapseTOC