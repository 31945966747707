import { useEffect, useRef } from "react"
import { WidgetInstance } from "friendly-challenge"

const Captcha = ({ setSolution }) => {
  const container = useRef()
  const widget = useRef()

  const doneCallback = (solution) => {
    console.log("Captcha was solved. The form can be submitted.")
    setSolution(solution)
  }

  const errorCallback = (err) => {
    console.log("There was an error when trying to solve the Captcha.")
  }

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: "auto",
        doneCallback: doneCallback,
        errorCallback: errorCallback,
      })
    }

    return () => {
      if (widget.current != undefined) widget.current.reset()
    }
  }, [container])

  return (
    <div
      ref={container}
      className="frc-captcha mt-3 border-1 border-secondary bg-light"
      data-sitekey="FCMIQLJ4NUGCIDBK"
    />
  )
}

export default Captcha
