import React from "react"
import Hero from "./Home/Hero"
import Reseller from "./Home/Reseller"

import Container from "react-bootstrap/Container"
import { Helmet } from "react-helmet"

import PostPlaceholder from "./../images/Dirk_Mielke.jpeg"
// import Serie2023 from "./../images/escape-2021-electric-motion.webp"
// import ElektroTrial from "./../images/elektro-trial_electric-motion.webp"
import ModelCard from "./../pages/Modelle/ModelCard"
import Epure from "./../images/epure-electric-motion_card-picture.webp"
import Escape from "./../images/escape-2023.webp"

// import {
//   faStore,
//   faMessage,
//   faCircleInfo,
//   faMotorcycle,
// } from "@fortawesome/free-solid-svg-icons"
import { Row } from "react-bootstrap"
import ContactForm from "./Contact/ContactForm"

function Home() {
  return (
    <>
      <Helmet>
        <title>Electric Motion Fachhändler Dirk Mielke</title>
      </Helmet>
      <Container>
        <Hero />
        <hr className="pb-4" />
        <h2 id="modelle">Modelle</h2>
        <Row xs={1} md={2} lg={4}>
          <ModelCard
            title="Epure 2024"
            picture={Epure}
            description='Das "Wettkampf"-Sport-Modell mit Straßenzulassung und Kupplung.'
            model1price="8.900€"
            model1="Epure Sport"
            model2price="10.900€"
            model2="Epure Race"
          />
          <ModelCard
            title="Escape 2024"
            picture={Escape}
            description='Das "Touring"-Sport-Modell mit erhöhter Reichweite, wahlweise auch mit Kupplung.'
            model1price="10.250€"
            model1="Escape S"
            model2price="10.950€"
            model2="Escape R"
          />
        </Row>
        <hr className="pb-4" />
        <h2 id="kontakt">Kontakt</h2>
        {/* <Reseller align="end" image={Serie2023} logo={true} title="Die 2023 Serie von Electric Motion." cta={{ title: "Aktuellste Serie ansehen", url: "/2023", icon: faMotorcycle }} />
                <Reseller align="start" image={ElektroTrial} title="Elektro Trial Motorräder: Vor- und Nachteile." cta={{ title: "Ratgeber aufrufen", url: "/elektrotrial", icon: faCircleInfo }} /> */}
        <ContactForm />
        <hr className="pb-4" />
        <Reseller
          align="end"
          image={PostPlaceholder}
          logo={true}
          title="Ihr offizieller Fachhändler für ganz Deutschland."
          // cta={{
          //   title: "Electric Motion Bike kaufen",
          //   url: "/#kontakt",
          //   icon: faStore,
          // }}
        />
      </Container>
      <Reseller />
    </>
  )
}

export default Home
