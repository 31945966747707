import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

function ExtLink(props) {
    let icon;
    if (props.icon !== undefined) {
        icon = <FontAwesomeIcon className="me-2" icon={props.icon} size="sm" />
    } else {
        icon = <></>
    }


    return (
        <Button
            as={Link}
            className={"d-flex-inline align-items-center " + props.className}
            variant={props.variant}
            to={props.url}
            href={props.url}
            onClick={() => window.location.replace(props.url)}
        >
            {icon}
            {props.title}
        </Button>
    )
}

export default ExtLink