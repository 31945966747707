import React from 'react'
import Author from './../../images/niklas-pape-elektro-trial.jpg'
import ProfilePicturePlaceholder from './../../images/profile-picture-placeholder.jpg'
import ExtLink from '../../components/ExtLink'
import CollapseContent from './CollapseContent'

import { faMessage, faUser } from '@fortawesome/free-solid-svg-icons'

function Autor() {
    return (
        <CollapseContent title="Über den Autor" openOnMobile={true} openOnDesktop={true} opened={faUser} closed={faUser} content={
            <>
                <img className="rounded float-start me-3 mb-3" src={ProfilePicturePlaceholder} />
                <p>Hallo, mein Name ist Dirk Mielke und ich bin der offizielle Vertriebspartner für Electric Motion Motorräder in Deutschland.<br />Auch in meiner Freizeit beschäftige ich mich mit dem Trial-Sport, denn ich fahre seit 10 Jahren leidenschaftlich Trial-Motorräder.<br />Sollten Fragen zu den Motorrädern offen geblieben sein oder bei Interesse an einem Electric Motion Bike, können Sie mich gerne kontaktieren.</p>
                <ExtLink icon={faMessage} variant="primary" title="Kontakt aufnehmen" url="/kontakt" />

            </>} />

    )
}

export default Autor