import React from 'react'
import { Container } from 'react-bootstrap'

function Werkstatt() {
  return (
    <Container>
        Werkstatt
    </Container>
  )
}

export default Werkstatt