import React, { useEffect, useState } from 'react'

import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

function CollapseContent(props) {

    const [open, setOpen] = useState(true);

    const [icon, setIcon] = useState(faCaretRight);

    useEffect(() => {
        if (window.innerWidth >= 992) {
            setOpen(props.openOnDesktop);
        } else {
            setOpen(props.openOnMobile)
        }
    }, [props.openOnDesktop, props.openOnMobile])

    useEffect(() => {
        if (open) {
            setIcon(props.opened);
        } else {
            setIcon(props.closed);
        }
    }, [open, props.opened, props.closed])

    return (
        <div className={"border rounded border-secondary px-4 py-3 my-2 " + props.className}>
            <Button
                variant="link"
                onClick={() => setOpen(!open)}
                aria-controls="collapsable-text"
                aria-expanded={open}
                className="mb-0 p-0 text-decoration-none d-flex align-items-center w-100"
            >
                <FontAwesomeIcon className="me-2" icon={icon} size="sm" />
                <h2 className="fs-3 mb-0 d-inline text-start">{props.title}</h2>
            </Button>

            <Collapse in={open}>
                <div id="collapsable-text">
                    <hr></hr>
                    {props.content}
                </div>
            </Collapse>
        </div>
    )
}

export default CollapseContent