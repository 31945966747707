import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import TOC from './Artikel/TOC'
import Autor from './Artikel/Autor'
import { Helmet } from 'react-helmet'

function Artikel(props) {

  return (
    <>
      <Helmet>
        <title>{props.title} - Electric Motion Fachhändler Dirk Mielke</title>
      </Helmet>
      <Container>
        <img className="hero rounded shadow my-3 w-100" src={props.image} />
        <h1 className="ausprobe text-center display-5">{props.title}</h1>
        <hr />
        <Row>
          <Col className="mt-4">
            {props.content}
            <hr className="my-4" />
            <Autor />

            {/* <Downloads /> */}
          </Col>
          <Col className="sticky-nav sticky-top align-self-start order-first" lg={4}>
            <TOC title={props.title} />
          </Col>
        </Row>
        <Row>
          <Col>
          </Col>
          <Col xs={8}>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Artikel