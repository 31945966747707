import React from 'react'
import { Col, Nav, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { Link, useLocation } from 'react-router-dom'
import generatedGitInfo from './../generatedGitInfo.json'

const navigationElements = [
    { title: 'Impressum', path: '/impressum' },
    { title: 'Datenschutz', path: '/datenschutz' }
]

function Footer() {

    const location = useLocation();

    return (
        <Container className="pt-3 pb-5 mt-3 mb-5 pb-lg-3 mb-lg-0 border-top border-secondary">
            <Row>
                <Col>
                    <code className="text-primary mb-2">
                        {generatedGitInfo.gitBranch}/<kbd>{generatedGitInfo.gitCommitHash}</kbd>
                    </code>
                </Col>
                <Col>
                    <Nav className="justify-content-end" activeKey="/home">
                        {
                            navigationElements.map((pNavElement, index) => {
                                return (
                                    <Nav.Link
                                        as={Link}
                                        key={pNavElement.path}
                                        role="button"
                                        className={"nav-link text-decoration-none " + (location.pathname === pNavElement.path ? "fw-bold" : "opacity-75")}
                                        to={pNavElement.path}
                                        href={pNavElement.path}
                                    >
                                        {pNavElement.title}
                                    </Nav.Link>
                                )
                            })
                        }
                    </Nav>
                </Col>
            </Row>

        </Container>
    )
}

export default Footer