import React from "react"
import Container from "react-bootstrap/Container"
import { Helmet } from "react-helmet"
import ContactForm from "./Contact/ContactForm"
import PostPlaceholder from './../images/post-placeholder.svg'

function Contact() {
  return (
    <>
      <Helmet>
        <title>Kontakt - Electric Motion Fachhändler Dirk Mielke</title>
      </Helmet>
      <Container className="mt-3">
        <img className="hero rounded shadow my-3 w-100" src={PostPlaceholder} />
        <h1 className="ausprobe text-center display-5">Kontakt</h1>
        <hr className="mb-5" />
        <ContactForm />
      </Container>
    </>
  )
}

export default Contact
