import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

function Impressum() {
  return (
    <>
      <Helmet>
        <title>Impressum - Electric Motion Fachhändler Dirk Mielke</title>
      </Helmet>
      <Container>
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Dirk Mielke<br />
          KFZ Meisterbetrieb und Fachh&auml;ndler f&uuml;r Electric Motion Motorr&auml;der<br />
          Sch&ouml;tmarsche Str. 21<br />
          33818 Leopoldsh&ouml;he</p>

        <h2>Kontakt</h2>
        <p>Telefon: 05208 390<br />
          E-Mail: kontakt@emkfz.de</p>

        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
          DE124569334</p>

        <h2>Redaktionell verantwortlich</h2>
        <p>Dirk Mielke<br />
          Sch&ouml;tmarsche Str. 21<br />
          33818 Leopoldsh&ouml;he</p>

        <h2>EU-Streitschlichtung</h2>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

        <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
      </Container>
    </>
  )
}

export default Impressum