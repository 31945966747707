import React from 'react'
import './custom.scss'
import { Route, Routes } from 'react-router-dom'

import Home from './pages/Home'
import Contact from './pages/Contact'

import Navigation from './components/Navigation'
import Serie2023 from './pages/Serie2023'
import Werkstatt from './pages/Werkstatt'
import Modelle from './pages/Modelle'
import Footer from './components/Footer'
import Impressum from './pages/Impressum'
import Datenschutz from './pages/Datenschutz'
import ElektroTrial from './pages/ElektroTrial'


function App() {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/2023" element={<Serie2023 />} />
        <Route path="/werkstatt" element={<Werkstatt />} />
        <Route path="/modelle" element={<Modelle />} />
        <Route path="/elektrotrial" element={<ElektroTrial />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
      </Routes>
      <Footer />
    </>

  )
}

export default App