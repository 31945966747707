import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import { Link, useLocation } from "react-router-dom"

import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Logo from "./../images/electric-motion-logo.svg"
import ExtLink from "./ExtLink"

const navigationElements = [
  { title: "Startseite", path: "/" },
  // { title: '2023 Serie', path: '/2023' },
  // { title: 'Elektro Trial', path: '/elektrotrial' },
  // { title: 'Modelle', path: '/modelle' },
  // { title: 'Kontakt', path: '/kontakt' },
]

function Navigation() {
  const location = useLocation()

  return (
    <>
      <Navbar
        collapseOnSelect
        bg="light"
        expand="lg"
        className="border-top border-bottom border-secondary pt-3"
        fixed={"bottom"}
        sticky="lg-top"
      >
        <Container fluid="xl">
          <Navbar.Brand className="me-0">
            <Link className="text-decoration-none" to="/">
              <img
                src={Logo}
                width="150"
                className="d-inline-block align-top"
                alt="Electric Motion Logo"
              />
            </Link>
            <br></br>
            <span className="fs-6 p-0">
              {/* Fachhändler <Link to="/kontakt">Dirk Mielke</Link> */}
              Fachhändler Dirk Mielke
            </span>
          </Navbar.Brand>
          <Navbar.Toggle className="px-3 py-2" aria-controls="basic-navbar-nav">
            <FontAwesomeIcon className="mb-1" icon={faAngleDown} size="lg" />
            <br />
            <span className="fs-6">Menü</span>
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <hr></hr>
            <Nav as="nav" className="ms-4 me-auto">
              {navigationElements.map((pNavElement, index) => {
                return (
                  <Nav.Link
                    as={Link}
                    key={pNavElement.path}
                    role="button"
                    className={
                      "px-2 py-1 text-end text-lg-start text-decoration-none " +
                      (location.pathname === pNavElement.path
                        ? "fw-bold"
                        : "opacity-75")
                    }
                    to={pNavElement.path}
                    href={pNavElement.path}
                    eventKey={index}
                  >
                    {pNavElement.title}
                  </Nav.Link>
                )
              })}
            </Nav>
            <hr></hr>
            {/* <Nav className=""> */}
            <Row className="align-items-center justify-content-end col-auto">
              <Col className="col-auto">
                <p className="mb-0 me-0 text-end">
                  Ab 8.900€
                  <br />
                  <span className="text-dark opacity-75">inkl MwSt.</span>
                </p>
              </Col>
              <Col className="col-auto">
                <ExtLink title="Händler kontaktieren" url="#kontakt" />
              </Col>
            </Row>
            {/* </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Navigation
